// List of slugs for all not-author templates. These will never change
// since we aren't making any more (yay!) and therefore it's easiest to
// just put that list here instead of making an API call.
// eslint-disable-next-line import/prefer-default-export
export const nonWaymarkAuthorTemplateSlugs = [
  'acension_spring',
  'a_click_away',
  'back_to_school_go_big',
  'berlin',
  'big_text',
  'black_coffee',
  'bold',
  'chicken',
  'choices',
  'connected',
  'currents',
  'dancing_keys',
  'earth_day',
  'earth_tones',
  'elevate',
  'endless_highway',
  'espresso',
  'fathers_day_earth_tones',
  'finally',
  'five_seconds_of_awesome',
  'flip',
  'ford_convoy',
  'ford_future',
  'ford_multiscreen',
  'funk_it',
  'funk_it_redux',
  'go_ahead',
  'go_big',
  'good_routines',
  'gridlines',
  'handcraft',
  'harper',
  'harper_mothers_day',
  'hero',
  'hey_mashup',
  'holiday_go_ahead',
  'holiday_keystrokes',
  'holiday_pics',
  'holiday_tones_card',
  'holiday_tones_sale',
  'i_am',
  'insta_fresh',
  'intrepid',
  'intrepid_brand',
  'jewel',
  'journey_to_the_moon',
  'just_like_her',
  'keystrokes',
  'lets_go',
  'lunch_grain',
  'magic_marker',
  'momentum',
  'mothers_day_2019',
  'our_story',
  'persona_stand_out',
  'pics_or_it_didnt_happen',
  'pop',
  'quicken_loans_march_madness',
  'retro',
  'rock_it',
  'saga',
  'saga_memorial_day',
  'search',
  'shine',
  'showcase',
  'snowy_keys_card',
  'snowy_keys_sale',
  'social',
  'spinning',
  'spotlight',
  'spotlight_brand',
  'st_paddys_rock_it',
  'summerset',
  'summersethd',
  'sunny',
  'sunny_brand',
  'surprise',
  'swing',
  'the_big_one',
  'the_feels_dancer',
  'the_text',
  'times_square',
  'times_square_4th_of_july',
  'toro',
  'ultra',
  'valentines_choices',
  'vhs',
  'we_are',
  'we_got_you',
  'welcome_to_the_machine',
  'womens_day',
  'your_move',
  'zebra',
];

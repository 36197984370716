import { Shape, ShapeKeyframed } from './shapes';
import { Value, ValueKeyframed } from './values';

export enum MaskMode {
  None = 'n',
  Add = 'a',
  Subtract = 's',
  Intersect = 'i',
  Lighten = 'l',
  Darken = 'd',
  Difference = 'f',
}

export interface Mask {
  /** Inverted Mask flag */
  inv: boolean;
  /** Mask name. Used for expressions and effects. */
  nm: string;
  /** Mask vertices ("maskShape") */
  pt: Shape | ShapeKeyframed;
  /** "Mask Opacity" */
  o: Value | ValueKeyframed;
  /** "Mask Expansion" */
  x: Value | ValueKeyframed;
  /** "Mask Feather" */
  f?: Value | ValueKeyframed;
  /**
   * Mask mode. Not all mask types are supported.
   * */
  mode: `${MaskMode}`;
}

import { UnformattedConfiguredVideo } from 'shared/api/types';
import { safeLocalStorage } from 'shared/utils/safeStorage';

export const fanoutVideoRenderCompletedEvent = {
  eventName: 'wm:fanoutVideoRenderCompleted',
  emit(configuredVideo: UnformattedConfiguredVideo) {
    document.dispatchEvent(
      new CustomEvent(this.eventName, {
        detail: configuredVideo,
      }),
    );
  },
  subscribe(callback: (configuredVideo: UnformattedConfiguredVideo) => void) {
    const listener = (event: CustomEvent<UnformattedConfiguredVideo>) => callback(event.detail);
    document.addEventListener(this.eventName, listener as EventListener);
    // Return an unsubscribe callback
    return () => document.removeEventListener(this.eventName, listener as EventListener);
  },
};

export const autofillGenerationCountEvent = {
  eventName: 'wm:autofillGenerationCount',
  emit() {
    let generationCount = parseInt(safeLocalStorage.getItem('autofillGenerationCount') || '0', 10);
    generationCount += 1;

    if (generationCount > 4) {
      // Reset the count and update localStorage
      generationCount = 0;
      safeLocalStorage.setItem('autofillGenerationCount', generationCount.toString());
    } else if (generationCount === 1) {
      // Dispatch the event, increment the count, and update localStorage
      document.dispatchEvent(new CustomEvent(this.eventName));
      safeLocalStorage.setItem('autofillGenerationCount', generationCount.toString());
    } else {
      safeLocalStorage.setItem('autofillGenerationCount', generationCount.toString());
    }
  },
  subscribe(callback: () => void) {
    const listener = () => callback();
    document.addEventListener(this.eventName, listener as EventListener);
    // Return an unsubscribe callback
    return () => document.removeEventListener(this.eventName, listener as EventListener);
  },
};

import { configure } from 'safe-stable-stringify';
import traverse from 'traverse';

import { LogRecordData } from './record';

export const REDACTION_MARKER = '[REDACTED]';
export const CIRCULAR_REFERENCE_MARKER = '[CIRCULAR REFERENCE REDACTED]';

export const stringify = configure({ circularValue: CIRCULAR_REFERENCE_MARKER });

export const SECRET_PATTERN =
  /(auth|([-_\.]|(api.*))key|p(w|ass(w(or)?d)?)|secret|session.*id|token$)/i;

export const JSON_STRING_KEYS = ['body'];

/**
 * Replacer function for JSON.stringify that redacts sensitive information, even in JSON strings,
 * and tries to do something useful with Errors, Symbols, URLs.
 */
export function makeRedactionReplacer(
  marker: string = REDACTION_MARKER,
  propertyPatterns: RegExp[] = [SECRET_PATTERN],
) {
  return (key: string, value: any): any => {
    if (propertyPatterns.some((pattern) => key && pattern.test(key))) {
      // Anything we want to always redact: secrets, or big objects with circular references.
      return marker;
    } else if (value instanceof URL) {
      return value.href;
    } else if (typeof value === 'symbol') {
      return String(value);
    } else if (value && JSON_STRING_KEYS.includes(key)) {
      // Properties that contain JSON strings, so should be parsed and redacted, then
      // stored as strings again.
      try {
        const parsedValue = JSON.parse(value);
        const redactedValue = redact(parsedValue, marker, propertyPatterns);
        const redactedValueString = stringify(
          redactedValue,
          makeRedactionReplacer(marker, propertyPatterns),
        );
        return redactedValueString;
      } catch (e) {
        // Only so much we can do. If it can't be edited as JSON, leave it be.
        console.warn(`Error redacting JSON string key "${key}": %s`, getLoggableError(e));
      }
    } else if (value instanceof Error) {
      // Errors don't have enumerable own properties, so will just get dropped if passed to
      // JSON.stringify. This is not helpful, so instead, let's create copies of them that can be
      // logged, as suggested here:
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#description
      const newErrorThing = Object.getOwnPropertyNames(value).reduce(
        (acc: Record<string, any>, name: string) => {
          acc[name] = (value as { [key: string]: any })[name];
          return acc;
        },
        {},
      );
      // Also, stack trace strings are hard to read in logs, so let's break them into lines.
      if ('stack' in newErrorThing && !('stackLines' in newErrorThing)) {
        newErrorThing.stackLines = newErrorThing.stack
          .split('\n')
          .map((line: string) => line.trim());
      }
      return newErrorThing;
    }
    return value;
  };
}

/**
 * Redacts log metadata.
 *
 * Circular references are replaced with CIRCULAR_REFERENCE_MARKER. The values of properties whose
 * names match patterns presumed to indicate secrets (like passwords, API tokens, keys) will be
 * redacted.
 */
export function redact(
  obj?: LogRecordData,
  marker: string = REDACTION_MARKER,
  propertyPatterns: RegExp[] = [SECRET_PATTERN],
): LogRecordData | undefined {
  if (obj === undefined) {
    return undefined;
  }

  const stringifiedObj = stringify(obj, makeRedactionReplacer(marker, propertyPatterns));
  if (stringifiedObj === undefined) {
    return undefined;
  }

  return JSON.parse(stringifiedObj);
}

/**
 * Extracts the message from an Error, or stringifies anything else.
 *
 * Useful if you just want the Error message outside of a redaction context.
 */
export function getLoggableError(e: unknown): string {
  if (e instanceof Error) {
    return e.message;
  }
  return stringify(e) as string;
}

// Vendor
import { getLogger } from 'libs/logging-ts';

// Local
import { Context } from './context';
import { AnyRequest, AnyRequestSchema, unwrapRequest } from './request';

export type MiddlewareFunction = (ctx: Context, next: () => Promise<void>) => void;

const logger = getLogger({ metadata: { service: 'lambda-routing' } });

export const JSONBodyParser = async (ctx: Context, next: () => Promise<void>) => {
  ctx.state.requestBody = ctx.event.body ? JSON.parse(ctx.event.body) : ctx.event.body;
  await next();
};

/**
 * Parses the request as a standard metadata/request to help propagate the correlation ID.
 */
export const JSONStandardRequestBodyParser = async (ctx: Context, next: () => Promise<void>) => {
  if (ctx.event.body) {
    const requestBody = JSON.parse(ctx.event.body);

    // This call will deserialize a standard request (ie. with metadata and request properties)
    // and place the metadata andrequest in the ctx.state.requestMetadata and
    // ctx.state.requestBody properties respectively. If the request is not a standard request,
    // it will be placed in the ctx.state.requestBody property as-is and the metadata will be
    // empty.
    const { metadata, request } = unwrapRequest<AnyRequest>(requestBody, AnyRequestSchema);

    ctx.state.requestMetadata = metadata;
    ctx.state.requestBody = request;
  } else {
    // GET requests must use the query string for request data and metadata. Right now the only
    // metadata we recognize is a correlation ID query string.
    // TODO: We can consider adding a check for a correlation ID in the headers here as well.
    const correlationID =
      ctx.event.queryStringParameters?.correlationID ?? ctx.lambdaContext?.awsRequestId ?? '';

    ctx.state.requestMetadata = { correlationID };
    ctx.state.requestBody = ctx.event.body;
  }

  await next();
};

export const NoOpMiddleware = async (ctx: Context, next: () => Promise<void>) => await next();

/**
 * Check if a request's Origin header value is an origin we'll allow via CORS.
 */
function isAllowedOrigin(origin: string): boolean {
  const allowedDomains = ['video-preview.com', 'waymark.com'];
  const allowedOrigins = [...allowedDomains];
  if (process.env.APPLICATION_ENVIRONMENT !== 'wm-prod') {
    allowedOrigins.unshift('localhost');
  }

  if (origin === null || origin === undefined) {
    return false;
  }

  const originURL = new URL(origin);

  // Return true immediately for an exact match to one of our allowed origins.
  if (allowedOrigins.includes(originURL.hostname)) {
    return true;
  }

  // If the origin doesn't exactly match an allowed origin, see if it's a subdomain of one of our
  // allowed domains.
  return allowedDomains.some((domain) => {
    return originURL.hostname.endsWith(`.${domain}`);
  });
}

// Return CORS headers for all requests.
/* eslint-disable-next-line @typescript-eslint/ban-types */
export const CORSMiddleware = async (ctx: Context, next: () => Promise<void>) => {
  logger.debug('Setting CORS headers', ctx);

  // https://w3c.github.io/webappsec-cors-for-developers/#use-vary
  ctx.response.setHeader('Vary', 'Origin');

  const { origin } = ctx.event.headers;

  // We only set the ACAO header for allowed origins, and never to null.
  // https://w3c.github.io/webappsec-cors-for-developers/#avoid-returning-access-control-allow-origin-null
  if (origin && isAllowedOrigin(origin)) {
    logger.debug('Setting Access-Control-Allow-Origin', { origin });
    ctx.response.setHeader('Access-Control-Allow-Origin', origin);
  }

  // Include the AWS authorization headers
  ctx.response.setHeader(
    'Access-Control-Allow-Headers',
    'accept, origin, content-type, authorization, x-api-key, x-amz-date, x-amz-security-token',
  );
  ctx.response.setHeader('Access-Control-Allow-Methods', 'HEAD,POST,OPTIONS,GET,PATCH,PUT');
  ctx.response.setHeader('Access-Control-Allow-Credentials', 'true');
  ctx.response.setHeader('Access-Control-Max-Age', '86400');

  // OPTIONS requests don't need a response other than the headers, but all requests need the
  // CORS headers.
  if (ctx.event.requestContext.http.method === 'OPTIONS') {
    return;
  }

  await next();
};

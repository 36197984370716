import { FetchPolicy, useQuery } from '@apollo/client';
import { ResultOf } from '@graphql-typed-document-node/core';

import { graphql } from '@libs/graphql-types';

import './fragments';
import { apolloClient } from '..';

export const accountUserVideoCountQuery = graphql(/* GraphQL */ `
  query AccountUserVideoCount($accountGUID: String) {
    accountByGuid(guid: $accountGUID) {
      id
      userVideos {
        totalCount
      }
    }
  }
`);

export const useAccountTotalUserVideoCount = (accountGUID: string | null) => {
  const { data, loading: isLoading } = useQuery(accountUserVideoCountQuery, {
    variables: {
      accountGUID,
    },
    skip: !accountGUID,
  });

  return { totalCount: data?.accountByGuid?.userVideos?.totalCount ?? 0, isLoading };
};

export const accountPageUserVideosQueryName = 'AccountPageUserVideos';
export const accountPageUserVideosQuery = graphql(/* GraphQL */ `
  query AccountPageUserVideos(
    $accountGUID: String!
    $first: Int
    $after: String
    $orderBy: [String]
    $isPurchased: Boolean
    $searchQuery: String
  ) {
    accountByGuid(guid: $accountGUID) {
      id
      unfilteredUserVideos: userVideos {
        totalCount
      }
      userVideos(
        first: $first
        after: $after
        orderBy: $orderBy
        isPurchased: $isPurchased
        searchQuery: $searchQuery
      ) {
        totalCount
        edges {
          cursor
          node {
            ...UserVideoAccountPageFieldsFragment
          }
        }
      }
    }
  }
`);

export const accountPageUserVideoAndDerivedVideosQuery = graphql(/* GraphQL */ `
  query AccountPageUserVideoAndDerivedVideos(
    $userVideoGUID: String
    $orderBy: [String]
    $searchQuery: String
  ) {
    userVideoByGuid(guid: $userVideoGUID) {
      ...UserVideoAccountPageFieldsFragment
      derivedAccountPageUserVideos: derivedUserVideos(
        orderBy: $orderBy
        searchQuery: $searchQuery
      ) {
        totalCount
        edges {
          node {
            ...UserVideoAccountPageFieldsFragment
          }
        }
      }
    }
  }
`);

const sdkUserVideoFragment = graphql(/* GraphQL */ `
  fragment SDKUserVideo on UserVideoNode {
    ...CoreUserVideoFragment
    ...UserVideoRendersFragment
  }
`);

export type SDKUserVideo = ResultOf<typeof sdkUserVideoFragment>;

const sdkAllAccountUserVideosQuery = graphql(/* GraphQL */ `
  query SDKAllAccountUserVideos($accountGUID: String!) {
    accountByGuid(guid: $accountGUID) {
      id
      userVideos {
        edges {
          node {
            ...SDKUserVideo
          }
        }
      }
    }
  }
`);

export const getSDKUserVideosForAccount = async (
  accountGUID: string,
  fetchPolicy: FetchPolicy = 'network-only',
) => {
  return apolloClient.query({
    query: sdkAllAccountUserVideosQuery,
    variables: {
      accountGUID,
    },
    fetchPolicy,
  });
};

const sdkUserVideoByGUIDQuery = graphql(/* GraphQL */ `
  query UserVideoByGUID($userVideoGUID: String!) {
    userVideoByGuid(guid: $userVideoGUID) {
      ...SDKUserVideo
    }
  }
`);

/**
 * Directly query for a user video by GUID
 */
export const getSDKUserVideoByGUID = async (
  userVideoGUID: string,
  fetchPolicy: FetchPolicy = 'network-only',
) => {
  return apolloClient.query({
    query: sdkUserVideoByGUIDQuery,
    variables: {
      userVideoGUID,
    },
    fetchPolicy,
  });
};

const userVideoApprovalsQuery = graphql(/* GraphQL */ `
  query UserVideoApprovals($userVideoGUID: String!) {
    userVideoByGuid(guid: $userVideoGUID) {
      id
      guid
      userVideoApprovals {
        ...UserVideoApprovalFragment
      }
    }
  }
`);

/**
 * Hook queries for and returns the user video approval records for a given user video GUID
 */
export const useUserVideoApprovals = (userVideoGUID: string | null) => {
  const { data, loading: isLoading } = useQuery(userVideoApprovalsQuery, {
    variables: {
      userVideoGUID: userVideoGUID ?? '',
    },
    skip: !userVideoGUID,
  });

  return { data: data?.userVideoByGuid ?? null, isLoading };
};

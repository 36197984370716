import { graphql } from '@libs/graphql-types';

import { apolloClient } from '../index';

const STOCK_IMAGES_FOR_IMAGE_KEYS_QUERY = graphql(/* GraphQL */ `
  query StockImagesForImageKeys($imageKeys: [String!]!) {
    stockImagesForImageKeys(imageKeys: $imageKeys) {
      id
      stockAssetId
      stockSearchId
    }
  }
`);

/**
 * Query fetches all stock images which match an array of image keys (found in the configuration at imageField.content.location.key)
 */
export const getStockImagesForImageKeys = async (imageKeys: string[]) => {
  if (!imageKeys || imageKeys.length === 0) {
    return [];
  }

  const { data } = await apolloClient.query({
    query: STOCK_IMAGES_FOR_IMAGE_KEYS_QUERY,
    variables: { imageKeys },
  });

  return data.stockImagesForImageKeys;
};

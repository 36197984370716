export interface ContentCropping {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ContentZoom {
  x: number; // 0 to 1
  y: number; // 0 to 1
  z: number; // 0 to 1
}

export enum FitFillAlignment {
  TopLeft = 'TL',
  TopCenter = 'TC',
  TopRight = 'TR',
  CenterLeft = 'CL',
  CenterCenter = 'CC',
  CenterRight = 'CR',
  BottomLeft = 'BL',
  BottomCenter = 'BC',
  BottomRight = 'BR',
}

export enum AssetModificationFits {
  Fill = 'fill',
  Crop = 'crop',
  /** TODO: this value can pop up in asset modifications, but it is a bug  */
  DeprecatedMax = 'max',
}

export interface ImageAdjustments {
  noiseReduction?: number; // -1 to 1
  noiseReductionSharpen?: number; // -1 to 1
  blur?: number; // 0 to 1000
  brightness?: number; // -1 to 1
  contrast?: number; // -1 to 1
  duotone?: [string, string];
  duotoneAlpha?: number; // 0 to 1
  exposure?: number; // -1 to 1
  highlight?: number; // -1 to 0
  monochrome?: string;
  saturation?: number; // -1 to 1
  shadow?: number; // 0 to 1
  sharpen?: number; // 0 to 1
  unsharpMask?: number; // -1 to 1
  vibrance?: number; // -1 to 1
}

type SharedImageVideoModifications = {
  adjustments?: ImageAdjustments;
  backgroundFill?: string;
  cropping?: ContentCropping;
  fillColor?: string; // #ffffff00
  fit?: `${AssetModificationFits}`;
  fitFillAlignment?: `${FitFillAlignment}`;
  padding?: number; // 0 to 500
  zoom?: ContentZoom;
};

export enum TimecodePlacement {
  Top = 'top',
  Bottom = 'bottom',
}

export interface TimecodeSettings {
  timecodePaddingTop?: number;
  timecodeDigitCount: number;
  timecodeDigitHeight: number;
  timecodeDigitWidth: number;
  timecodeent: `${TimecodePlacement}`;
}

export interface VideoAssetModifications extends SharedImageVideoModifications {
  shouldUseTimecode?: boolean;
  hasTimecode?: boolean;
  videoAssetHeightMode?: 'assetWithPadding' | 'assetOnly';
  videoAssetHeightPadding?: number;
  timecodeScaleMode?: 'relative' | 'fixed';
  timecodeSettings?: TimecodeSettings;
}

export type ImageAssetModifications = SharedImageVideoModifications;

/**
 * @namespace app.state
 */

import { createBrowserHistory as createHistory } from 'history';

// Create browser history.
const history = createHistory();

export default history;

import { ResultOf } from '@graphql-typed-document-node/core';
import { graphql } from '@libs/graphql-types';

export const lastEditedUserVideoInfoQueryName = 'MostRecentlyEditedUserVideoInfo';

export const lastEditedUserVideoInfoQuery = graphql(/* GraphQL */ `
  query MostRecentlyEditedUserVideoInfo($accountGUID: String!) {
    accountByGuid(guid: $accountGUID) {
      id
      lastEditedUserVideo: userVideos(first: 1, orderBy: "-last_edited_by_user") {
        edges {
          node {
            id
            guid
            lastEditedByUser
          }
        }
      }
      lastPurchasedUserVideo: userVideos(first: 1, orderBy: "-purchased_at") {
        edges {
          node {
            id
            guid
            purchasedAt
          }
        }
      }
    }
  }
`);

export const userVideoRenderedVideoFragment = graphql(/* GraphQL */ `
  fragment UserVideoRenderedVideo on RenderedVideoNode {
    id
    renderUrl
    renderFormat
    renderStatus
    renderSize
    hasWatermark
  }
`);

export const userVideoRendersQueryName = 'UserVideoRenders';
export const userVideoRendersQuery = graphql(/* GraphQL */ `
  query UserVideoRenders($userVideoGUID: String!) {
    userVideoByGuid(guid: $userVideoGUID) {
      id
      guid
      currentConfiguredVideo {
        id
        renderStatus
        renderedVideos {
          edges {
            node {
              ...UserVideoRenderedVideo
            }
          }
        }
      }
    }
  }
`);

export type UserVideoRendersQueryResult = ResultOf<typeof userVideoRendersQuery>;

import { z } from 'zod';

const RequestMetadataSchema = z.object({
  correlationID: z.string().optional(),
});

const StandardRequestSchema = <T>(dataSchema: z.ZodType<T>) =>
  z.object({
    metadata: RequestMetadataSchema,
    request: dataSchema,
  });

// For use when the handler will manage its own validation.
export const AnyRequestSchema = z.record(z.any());

export type StandardRequest<T> = z.infer<ReturnType<typeof StandardRequestSchema<T>>>;
export type Metadata = z.infer<typeof RequestMetadataSchema>;
export type AnyRequest = z.infer<typeof AnyRequestSchema>;

export function unwrapRequest<T>(data: unknown, dataSchema: z.ZodType<T>): StandardRequest<T> {
  const generalRequestSchema = StandardRequestSchema(dataSchema);
  const parsedRequest = generalRequestSchema.safeParse(data);

  let errorMessage = '';

  if (parsedRequest.success) {
    return parsedRequest.data;
  } else {
    errorMessage = parsedRequest.error.message;

    // If parsing fails, assume the data is the request and return it with empty metadata
    const parsedData = dataSchema.safeParse(data);
    if (parsedData.success) {
      return {
        metadata: {},
        request: parsedData.data as T,
      };
    } else {
      errorMessage += ` - ${parsedData.error.message}`;
    }
    throw new Error(`Invalid request: ${errorMessage}`);
  }
}

export function wrapRequest(data: AnyRequest, correlationID: string): StandardRequest<AnyRequest> {
  return {
    metadata: {
      correlationID,
    },
    request: data,
  };
}

import { BooleanInt } from './helpers';
import { Layer } from './layers';
import {
  AssetLocation,
  BitmapFontServiceLegacyLocation,
  BitmapFontServiceLocation,
  LegacyWaymarkAudioLocation,
  LegacyWaymarkVideoLocation,
  WaymarkApsLocation,
  WaymarkImageLocation,
  WaymarkTemplateStudioLocation,
  WaymarkVpsLocation,
} from './locations';
import { ImageAssetModifications, VideoAssetModifications } from './modifications';

export interface CharsSource {
  /** Character Value */
  ch: string;
  /** Character Font Family */
  fFamily: string;
  /** Character Font Size */
  size: string;
  /** Character Font Style */
  style: string;
  /** Character Width */
  w: number;
  /** Character Data */
  data: CharacterData;
}

export interface BaseBodymovinAsset {
  /** Asset Height */
  h: number;
  /** Asset Width */
  w: number;
  /** Asset ID */
  id: string;
}

export enum AssetType {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  BitmapFont = 'bitmapFont',
}

export interface AfterEffectsAsset extends BaseBodymovinAsset {
  /** Asset name */
  p: string;
  /** Asset path */
  u: string;
  /** Is encoded data url? */
  e?: BooleanInt;
  /** Asset type is sometimes defined post-studio-publish or via manual edit */
  type?: `${AssetType}`;
  /** Name is sometimes defined post-studio-publish or via manual edit */
  name?: string;
}
export interface ProjectManifestMediaAsset extends BaseBodymovinAsset {
  /** If present, disregard. */
  e?: 0;
  /** If present, disregard. */
  p?: string;
  /** If present, disregard. */
  u?: string;
  type: `${AssetType}`;
  location: AssetLocation;
}

export interface FlattenedFootageAsset extends ProjectManifestMediaAsset {
  type: `${AssetType.Video}`;
  name: string;
  footageCompositionFor: number[];
  sourceCompositionId: number;
  content?: {
    modifications?: null | VideoAssetModifications;
  };
  modifications?: null | VideoAssetModifications;
}

export interface ProjectManifestVideoAsset extends ProjectManifestMediaAsset {
  type: `${AssetType.Video}`;
  name?: string;
  location: WaymarkVpsLocation | LegacyWaymarkVideoLocation | WaymarkTemplateStudioLocation;
  content?: {
    modifications?: null | VideoAssetModifications;
  };
  modifications?: null | VideoAssetModifications;
}

export interface ProjectManifestImageAsset extends ProjectManifestMediaAsset {
  type: `${AssetType.Image}`;
  location: WaymarkImageLocation | WaymarkTemplateStudioLocation;
  content?: {
    modifications?: null | ImageAssetModifications;
  };
  modifications?: null | ImageAssetModifications;
}

export interface ProjectManifestAudioAsset extends ProjectManifestMediaAsset {
  type: `${AssetType.Audio}`;
  location: WaymarkApsLocation | LegacyWaymarkAudioLocation | WaymarkTemplateStudioLocation;
  modifications?: null | Record<string, never>;
}

export type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export interface ProjectManifestBitmapFontAsset {
  id: string;
  type: `${AssetType.BitmapFont}`;
  isItalic: boolean;
  // TODO: weight appears to not always be present. Seems weird.
  weight?: FontWeight;
  location:
    | BitmapFontServiceLocation
    | BitmapFontServiceLegacyLocation
    | WaymarkTemplateStudioLocation;
  /**
   * TODO: Modifications seem to sometimes accidentally get set to an empty object when
   * font assets do not support modifications.
   */
  modifications?: null | Record<string, never>;
}

export interface StudioAudioAsset {
  type: `${AssetType.Audio}`;
  /** The name specified in the studio */
  name?: string;
  /** The ID generated in the studio */
  id: string;
  /** The location of the asset */
  location: WaymarkApsLocation | LegacyWaymarkAudioLocation | WaymarkTemplateStudioLocation;
  /**
   * TODO: Modifications seem to sometimes accidentally get set to an empty object when
   * audio assets do not support modifications.
   */
  modifications?: null | Record<string, never>;
}

/** @migrationtodo */
export interface MalformStudioAudioAsset {
  /** Asset ID */
  id: string;
  /** Asset name */
  p: string;
  /** Asset path */
  u: string;
  /** Asset type is sometimes defined post-studio-publish or via manual edit */
  type: `${AssetType.Audio}`;
  /** The name */
  name: string;
}

export interface BaseComposition {
  /** List of Composition Layers */
  layers: Layer[];
  /** After Effects name */
  nm?: string;
  /**
   * Added in 2020. This is a unique identifier of the composition.
   *
   * https://github.com/stikdev/waymark-author-ae-extension/blob/2e886b9b9e440d3c185861b617da94efd8b0098f/src/host/lib/compItemIDManager.jsx#L36
   */
  compId?: number;
}

export interface PreCompSource extends BaseComposition {
  /** Precomp ID */
  id: string;
}

export type Source =
  | AfterEffectsAsset
  | PreCompSource
  | FlattenedFootageAsset
  | ProjectManifestImageAsset
  | ProjectManifestVideoAsset
  | ProjectManifestAudioAsset
  | ProjectManifestBitmapFontAsset
  | MalformStudioAudioAsset
  | StudioAudioAsset;

export const ACCOUNT_LOGIN = 'ACCOUNT_LOGIN';
export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT';
export const ACCOUNT_FETCH_COMPLETE = 'ACCOUNT_FETCH_COMPLETE';
export const ACCOUNT_FETCH_ERROR = 'ACCOUNT_FETCH_ERROR';
export const ACCOUNT_FETCH_PENDING = 'ACCOUNT_FETCH_PENDING';
export const ACCOUNT_RECEIVED_SERVICE_ACCESS_TOKEN = 'ACCOUNT_RECEIVED_SERVICE_ACCESS_TOKEN';
export const ACCOUNT_REQUEST_PASSWORD_RESET = 'ACCOUNT_REQUEST_PASSWORD_RESET';
export const ACCOUNT_RESET_EMAIL_COMPLETE = 'ACCOUNT_RESET_EMAIL_COMPLETE';
export const ACCOUNT_RESET_EMAIL_PENDING = 'ACCOUNT_RESET_EMAIL_PENDING';
export const ACCOUNT_RESET_EMAIL_ERROR = 'ACCOUNT_RESET_EMAIL_ERROR';
export const ACCOUNT_EMAIL_RESET_TIMEOUT = 'ACCOUNT_EMAIL_RESET_TIMEOUT';
export const ACCOUNT_RESET_PASSWORD_COMPLETE = 'ACCOUNT_RESET_PASSWORD_COMPLETE';
export const ACCOUNT_PASSWORD_RESET_TIMEOUT = 'ACCOUNT_PASSWORD_RESET_TIMEOUT';
export const ACCOUNT_RESET_PASSWORD_ERROR = 'ACCOUNT_RESET_PASSWORD_ERROR';
export const ACCOUNT_RESET_PASSWORD_PENDING = 'ACCOUNT_RESET_PASSWORD_PENDING';
export const ACCOUNT_SEND_PASSWORD_RESET_EMAIL_COMPLETE =
  'ACCOUNT_SEND_PASSWORD_RESET_EMAIL_COMPLETE';
export const ACCOUNT_SEND_PASSWORD_RESET_EMAIL_ERROR = 'ACCOUNT_SEND_PASSWORD_RESET_EMAIL_ERROR';
export const ACCOUNT_SEND_PASSWORD_RESET_EMAIL_PENDING =
  'ACCOUNT_SEND_PASSWORD_RESET_EMAIL_PENDING';
export const ACCOUNT_SUBSCRIPTIONS_FETCH_COMPLETE = 'ACCOUNT_SUBSCRIPTIONS_FETCH_COMPLETE';
export const ACCOUNT_SUBSCRIPTIONS_FETCH_ERROR = 'ACCOUNT_SUBSCRIPTIONS_FETCH_ERROR';
export const ACCOUNT_SUBSCRIPTIONS_FETCH_PENDING = 'ACCOUNT_SUBSCRIPTIONS_FETCH_PENDING';

export const BUSINESSES_CLEAR_ERROR = 'BUSINESSES_CLEAR_ERROR';
export const BUSINESSES_CREATE_COMPLETE = 'BUSINESSES_CREATE_COMPLETE';
export const BUSINESSES_CREATE_ERROR = 'BUSINESSES_CREATE_ERROR';
export const BUSINESSES_FETCH_COMPLETE = 'BUSINESSES_FETCH_COMPLETE';
export const BUSINESSES_FETCH_ERROR = 'BUSINESSES_FETCH_ERROR';
export const BUSINESSES_FETCH_PENDING = 'BUSINESSES_FETCH_PENDING';
export const BUSINESS_FETCH_COMPLETE = 'BUSINESS_FETCH_COMPLETE';
export const BUSINESS_FETCH_ERROR = 'BUSINESS_FETCH_ERROR';
export const BUSINESS_FETCH_PENDING = 'BUSINESS_FETCH_PENDING';
export const BUSINESS_SELECTED = 'BUSINESS_SELECTED';

export const BUSINESS_IMAGES_FETCH_COMPLETE = 'BUSINESS_IMAGES_FETCH_COMPLETE';
export const BUSINESS_IMAGES_FETCH_ERROR = 'BUSINESS_IMAGES_FETCH_ERROR';
export const BUSINESS_IMAGES_FETCH_PENDING = 'BUSINESS_IMAGES_FETCH_PENDING';
export const BUSINESS_IMAGES_REMOVE_IMAGE = 'BUSINESS_IMAGES_REMOVE_IMAGE';
export const BUSINESS_IMAGES_UPDATE_IMAGE = 'BUSINESS_IMAGES_UPDATE_IMAGE';
export const BUSINESS_IMAGE_REPLACE_PLACEHOLDER = 'BUSINESS_IMAGE_REPLACE_PLACEHOLDER';

// These action types are specific to the actual PartnerSearch state itself.
export const BUSINESS_SEARCH_PARTNER_SEARCH_CREATE_COMPLETE =
  'BUSINESS_SEARCH_PARTNER_SEARCH_CREATE_COMPLETE';
export const BUSINESS_SEARCH_PARTNER_SEARCH_CREATE_ERROR =
  'BUSINESS_SEARCH_PARTNER_SEARCH_CREATE_ERROR';
export const BUSINESS_SEARCH_PARTNER_SEARCH_SESSION_CREATE_COMPLETE =
  'BUSINESS_SEARCH_PARTNER_SEARCH_SESSION_CREATE_COMPLETE';
export const BUSINESS_SEARCH_PARTNER_SEARCH_SESSION_CREATE_ERROR =
  'BUSINESS_SEARCH_PARTNER_SEARCH_SESSION_CREATE_ERROR';
// Modify the overall status of a running search.
export const BUSINESS_SEARCH_CANCEL = 'BUSINESS_SEARCH_CANCEL';
export const BUSINESS_SEARCH_COMPLETED = 'BUSINESS_SEARCH_COMPLETED';
export const BUSINESS_SEARCH_START = 'BUSINESS_SEARCH_START';
export const BUSINESS_SEARCH_START_ERROR = 'BUSINESS_SEARCH_START_ERROR';
export const BUSINESS_SEARCH_STATUS_CHANGED = 'BUSINESS_SEARCH_STATUS_CHANGED';
export const BUSINESS_SEARCH_VALIDATED = 'BUSINESS_SEARCH_VALIDATED';
// Manage the receipt and selection of SearchResultGroups.
export const BUSINESS_SEARCH_RESULTS_RECEIVED = 'BUSINESS_SEARCH_RESULTS_RECEIVED';
export const BUSINESS_SEARCH_RESULT_GROUP_SELECTED = 'BUSINESS_SEARCH_RESULT_GROUP_SELECTED';
export const BUSINESS_SEARCH_RESULT_IMAGES_RECEIVED = 'BUSINESS_SEARCH_RESULT_IMAGES_RECEIVED';

export const CHECKOUT_SELECT_VIDEO_TO_PURCHASE = 'CHECKOUT_SELECT_VIDEO_TO_PURCHASE';
export const CHECKOUT_SET_VIDEO_OFFER_PURCHASE_INFO = 'CHECKOUT_SET_VIDEO_OFFER_PURCHASE_INFO';
export const CHECKOUT_SET_SUBSCRIPTION_OFFER_PURCHASE_INFO =
  'CHECKOUT_SET_SUBSCRIPTION_OFFER_PURCHASE_INFO';
export const CHECKOUT_SELECT_SUBSCRIPTION_OFFER_PRICING_FOR_PURCHASE =
  'CHECKOUT_SELECT_SUBSCRIPTION_OFFER_PRICING_FOR_PURCHASE';
export const CHECKOUT_APPLY_VIDEO_CREDIT_TO_PURCHASE = 'CHECKOUT_APPLY_VIDEO_CREDIT_TO_PURCHASE';
export const CHECKOUT_CLEAR = 'CHECKOUT_CLEAR';
export const CHECKOUT_DESELECT_OFFERS = 'CHECKOUT_DESELECT_OFFERS';
export const CHECKOUT_SET_PHASE = 'CHECKOUT_SET_PHASE';
export const CHECKOUT_SET_PREVIOUS_URL = 'CHECKOUT_SET_PREVIOUS_URL';

export const COLLECTION_FETCH_PENDING = 'COLLECTION_FETCH_PENDING';
export const COLLECTION_FETCH_COMPLETE = 'COLLECTION_FETCH_COMPLETE';
export const COLLECTION_FETCH_ERROR = 'COLLECTION_FETCH_ERROR';
export const COLLECTION_VIEW_FETCH_PENDING = 'COLLECTION_VIEW_FETCH_PENDING';
export const COLLECTION_VIEW_FETCH_COMPLETE = 'COLLECTION_VIEW_FETCH_COMPLETE';
export const COLLECTION_VIEW_FETCH_ERROR = 'COLLECTION_VIEW_FETCH_ERROR';

export const CONFIGURED_VIDEOS_RECEIVED_ALL_FOR_ACCOUNT =
  'CONFIGURED_VIDEOS_RECEIVED_ALL_FOR_ACCOUNT';
export const CONFIGURED_VIDEOS_RECEIVED_VIDEO = 'CONFIGURED_VIDEOS_RECEIVED_VIDEO';

export const EDITOR_CLEAR_EDITS = 'EDITOR_CLEAR_EDITS';
export const EDITOR_CLEAR_NEXT_URL = 'EDITOR_CLEAR_NEXT_URL';

export const EDITOR_HANDLE_BUSINESS_CHANGE = 'EDITOR_HANDLE_BUSINESS_CHANGE';
export const EDITOR_HANDLE_CONFIGURATION_CHANGE = 'EDITOR_HANDLE_CONFIGURATION_CHANGE';
export const EDITOR_HANDLE_VIDEO_TITLE_CHANGE = 'EDITOR_HANDLE_VIDEO_TITLE_CHANGE';
export const EDITOR_SET_CURRENTLY_EDITING_IMAGE_FIELD = 'EDITOR_SET_CURRENTLY_EDITING_IMAGE_FIELD';
export const EDITOR_SET_DEFAULT_CONFIGURATION = 'EDITOR_SET_DEFAULT_CONFIGURATION';
export const EDITOR_SET_UP_EDIT = 'EDITOR_SET_UP_EDIT';
export const EDITOR_SET_NEXT_URL = 'EDITOR_SET_NEXT_URL';
export const EDITOR_START_EDITING_INLINE_IMAGE = 'EDITOR_START_EDITING_INLINE_IMAGE';
export const EDITOR_MARK_EDITS_AS_SAVED = 'EDITOR_MARK_EDITS_AS_SAVED';
export const EDITOR_MARK_LOAD_FAILED = 'EDITOR_MARK_LOAD_FAILED';
export const EDITOR_SHOW_UNSAVED_CHANGES_MODAL = 'EDITOR_SHOW_UNSAVED_CHANGES_MODAL';
export const EDITOR_DISMISS_UNSAVED_CHANGES_MODAL = 'EDITOR_DISMISS_UNSAVED_CHANGES_MODAL';

export const INDUSTRY_IMAGES_FETCH_COMPLETE = 'INDUSTRY_IMAGES_FETCH_COMPLETE';
export const INDUSTRY_IMAGES_FETCH_ERROR = 'INDUSTRY_IMAGES_FETCH_ERROR';
export const INDUSTRY_IMAGES_FETCH_PENDING = 'INDUSTRY_IMAGES_FETCH_PENDING';

export const LOGIN_ACTION_ADD = 'LOGIN_ACTION_ADD';
export const LOGIN_ACTION_CLEAR = 'LOGIN_ACTION_CLEAR';

export const OFFERS_CLEAR = 'OFFERS_CLEAR';
export const OFFERS_FETCH_COMPLETE = 'OFFERS_FETCH_COMPLETE';
export const OFFERS_FETCH_ERROR = 'OFFERS_FETCH_ERROR';
export const OFFERS_FETCH_PENDING = 'OFFERS_FETCH_PENDING';
export const OFFERS_SELECT_OFFER = 'OFFERS_SELECT_OFFER';
export const OFFERS_CLEAR_OFFER = 'OFFERS_CLEAR_OFFER';
export const OFFERS_UPDATE_OFFER_CONTEXT = 'OFFERS_UPDATE_OFFER_CONTEXT';

export const PURCHASED_VIDEO_PRODUCTS_FETCH_COMPLETE = 'PURCHASED_VIDEO_PRODUCTS_FETCH_COMPLETE';
export const PURCHASED_VIDEO_PRODUCTS_FETCH_ERROR = 'PURCHASED_VIDEO_PRODUCTS_FETCH_ERROR';
export const PURCHASED_VIDEO_PRODUCTS_FETCH_PENDING = 'PURCHASED_VIDEO_PRODUCTS_FETCH_PENDING';
export const PURCHASED_VIDEO_PRODUCT_UPDATE = 'PURCHASED_VIDEO_PRODUCT_UPDATE';

export const PURCHASE_ATTEMPT_CLEAR = 'PURCHASE_ATTEMPT_CLEAR';
export const PURCHASE_ATTEMPT_COMPLETE = 'PURCHASE_ATTEMPT_COMPLETE';
export const PURCHASE_ATTEMPT_ERROR = 'PURCHASE_ATTEMPT_ERROR';
export const PURCHASE_ATTEMPT_PENDING = 'PURCHASE_ATTEMPT_PENDING';
export const PURCHASE_PREMIERE_UPSELL_COMPLETE = 'PURCHASE_PREMIERE_UPSELL_COMPLETE';
export const PURCHASE_PREMIERE_UPSELL_ERROR = 'PURCHASE_PREMIERE_UPSELL_ERROR';

export const RECEIPT_FETCH_COMPLETE = 'RECEIPT_FETCH_COMPLETE';
export const RECEIPT_FETCH_ERROR = 'RECEIPT_FETCH_ERROR';
export const RECEIPT_FETCH_PENDING = 'RECEIPT_FETCH_PENDING';

export const SAVED_DRAFTS_ADD = 'SAVED_DRAFTS_ADD';
export const SAVED_DRAFTS_FETCH_COMPLETE = 'SAVED_DRAFTS_FETCH_COMPLETE';
export const SAVED_DRAFTS_FETCH_ERROR = 'SAVED_DRAFTS_FETCH_ERROR';
export const SAVED_DRAFTS_FETCH_PENDING = 'SAVED_DRAFTS_FETCH_PENDING';
export const SAVED_DRAFTS_REMOVE = 'SAVED_DRAFTS_REMOVE';

export const SEARCH_RESULT_GROUPS_FETCH_COMPLETE = 'SEARCH_RESULT_GROUPS_FETCH_COMPLETE';
export const SEARCH_RESULT_GROUPS_FETCH_ERROR = 'SEARCH_RESULT_GROUPS_FETCH_ERROR';

export const SHOP_CLEAR_COUPON = 'SHOP_CLEAR_COUPON';
export const SHOP_CLOSE_COUPON_ALERT = 'SHOP_CLOSE_COUPON_ALERT';
export const SHOP_FETCH_COUPON_FAILURE = 'SHOP_FETCH_COUPON_FAILURE';
export const SHOP_FETCH_COUPON_PENDING = 'SHOP_FETCH_COUPON_PENDING';
export const SHOP_FETCH_COUPON_SUCCESS = 'SHOP_FETCH_COUPON_SUCCESS';
export const SHOP_USER_IMAGE_LIBRARY_SLUG_REQUESTED = 'SHOP_USER_IMAGE_LIBRARY_SLUG_REQUESTED';
export const SHOP_USER_VIDEO_ASSET_LIBRARY_SLUG_REQUESTED =
  'SHOP_USER_VIDEO_ASSET_LIBRARY_SLUG_REQUESTED';
export const SHOP_FETCH_GLOBAL_AUDIO_LIBRARIES_SUCCESS =
  'SHOP_FETCH_GLOBAL_AUDIO_LIBRARIES_SUCCESS';
export const SHOP_INITIALIZE_PENDING = 'SHOP_INITIALIZE_PENDING';
export const SHOP_INITIALIZE_COMPLETE = 'SHOP_INITIALIZE_COMPLETE';
export const SHOP_INITIALIZE_ERROR = 'SHOP_INITIALIZE_ERROR';
export const SHOP_RECEIVED_ANONYMOUS_USER_INFO = 'SHOP_RECEIVED_ANONYMOUS_USER_INFO';
export const SHOP_RECEIVED_BRANDING_PROFILE = 'SHOP_RECEIVED_BRANDING_PROFILE';
export const SHOP_SET_SHOULD_REFETCH = 'SHOP_SET_SHOULD_REFETCH';
export const SHOP_STATE_FETCH_COMPLETE = 'SHOP_STATE_FETCH_COMPLETE';
export const SHOP_STATE_FETCH_ERROR = 'SHOP_STATE_FETCH_ERROR';
export const SHOP_STATE_FETCH_PENDING = 'SHOP_STATE_FETCH_PENDING';
export const SHOP_STATE_UPDATED = 'SHOP_STATE_UPDATED';
export const SHOP_THIRD_PARTY_CONFIG_UPDATED = 'SHOP_THIRD_PARTY_CONFIG_UPDATED';
export const SHOP_SET_IS_ADMIN_PORTAL = 'SHOP_SET_IS_ADMIN_PORTAL';

export const UNSPLASH_IMAGES_FETCH_PENDING = 'UNSPLASH_IMAGES_FETCH_PENDING';
export const UNSPLASH_IMAGES_FETCH_ERROR = 'UNSPLASH_IMAGES_FETCH_ERROR';
export const UNSPLASH_IMAGES_FETCH_COMPLETE = 'UNSPLASH_IMAGES_FETCH_COMPLETE';
export const UNSPLASH_IMAGES_UPDATE_IMAGE = 'UNSPLASH_IMAGES_UPDATE_IMAGE';

export const USER_VIDEO_ADD = 'USER_VIDEO_ADD';
export const USER_VIDEO_FETCH_COMPLETE = 'USER_VIDEO_FETCH_COMPLETE';
export const USER_VIDEO_FETCH_ERROR = 'USER_VIDEO_FETCH_ERROR';
export const USER_VIDEO_FETCH_PENDING = 'USER_VIDEO_FETCH_PENDING';
export const USER_VIDEO_FETCH_ALL_PENDING = 'USER_VIDEO_FETCH_ALL_PENDING';
export const USER_VIDEO_FETCH_ALL_COMPLETE = 'USER_VIDEO_FETCH_ALL_COMPLETE';
export const USER_VIDEO_FETCH_ALL_ERROR = 'USER_VIDEO_FETCH_ALL_ERROR';
export const USER_VIDEO_UPDATE = 'USER_VIDEO_UPDATE';

export const VARIANT_FETCH_COMPLETE = 'VARIANT_FETCH_COMPLETE';
export const VARIANT_FETCH_ERROR = 'VARIANT_FETCH_ERROR';
export const VARIANT_FETCH_PENDING = 'VARIANT_FETCH_PENDING';

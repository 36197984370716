import {
  AutofillVideoDescriptorHydrated,
  AutofillVideoDescriptorThin,
  VideoDescriptor,
  VideoConfiguration,
  ProjectManifest,
  TemplateManifest,
} from '@libs/shared-types';

import WaymarkAuthorBundleManager from './WaymarkAuthorBundleManager';
import _ from 'lodash';

interface UnformattedVideoSpec {
  variant_slug: string;
  configuration: VideoConfiguration | VideoDescriptor;
}

interface FormattedVideoSpec {
  variantSlug: string;
  // Video specs may have an old-style configuration or a new-style video descriptor
  configuration: VideoConfiguration | VideoDescriptor;
}

const isVideoDescriptor = (obj: unknown): obj is VideoDescriptor =>
  typeof obj === 'object' &&
  obj !== null &&
  '__templateSlug' in obj &&
  '__activeConfiguration' in obj &&
  'projectManifest' in obj &&
  'templateManifest' in obj;

/**
 * Sanitizes the configuration by making sure image and imageOverride layers are formatted with a root level content object.
 * If a layer has both .content.location and .location, it will merge them, and .content.location will be used.
 * This ensures .content is used for content-related concerns.
 * This is to fix an issue in older configurations where the image and imageOverride layers were not formatted correctly.
 * @param VideoConfiguration
 * @returns VideoConfiguration
 * @example
 * Before:
 * 'imageOverride--1234': {
 *    location: {},
 *    type: 'image',
 *    modifications: { fit: 'crop', zoom: {} }
 * },
 * After:
 * 'imageOverride--1234': {
 *    content: {
 *      location: {},
 *      type: 'image',
 *      modifications: { fit: 'crop', zoom: {} }
 *    }
 * },
 */
export const getSanitizedConfiguration = (configuration: VideoConfiguration) => {
  const tempConfiguration = _.cloneDeep(configuration);

  Object.keys(tempConfiguration).forEach((key) => {
    if (key.includes('image--') || key.includes('imageOverride--')) {
      const fieldConfigurationValue = Object(tempConfiguration[key]);

      tempConfiguration[key] = {
        ..._.omit(fieldConfigurationValue, 'location', 'type', 'modifications'),
        content: {
          ..._.pick(fieldConfigurationValue, 'location', 'type', 'modifications'),
          ...fieldConfigurationValue['content'],
        },
      };
    }
  });

  return tempConfiguration;
};

/**
 * Takes a video spec or video descriptor in any of the various formats that they can sometimes be found in
 * and returns a fully formatted, sanitized VideoDescriptor object which we can pass around safely in the UI.
 */
export const getSanitizedVideoDescriptor = async (
  videoSpecOrVideoDescriptor:
    | UnformattedVideoSpec
    | FormattedVideoSpec
    | AutofillVideoDescriptorThin
    | AutofillVideoDescriptorHydrated
    | VideoDescriptor,
): Promise<VideoDescriptor> => {
  // If the object we received is already a good video descriptor, just return it
  if (isVideoDescriptor(videoSpecOrVideoDescriptor)) {
    videoSpecOrVideoDescriptor.__activeConfiguration = getSanitizedConfiguration(
      videoSpecOrVideoDescriptor.__activeConfiguration,
    );
    return videoSpecOrVideoDescriptor;
  }

  // If we got a video spec object where the configuration is already a video descriptor, we can just return it
  if (
    'configuration' in videoSpecOrVideoDescriptor &&
    isVideoDescriptor(videoSpecOrVideoDescriptor.configuration)
  ) {
    videoSpecOrVideoDescriptor.configuration.__activeConfiguration = getSanitizedConfiguration(
      videoSpecOrVideoDescriptor.configuration.__activeConfiguration,
    );
    return videoSpecOrVideoDescriptor.configuration;
  }

  // This could be a hydrated autofill video descriptor
  if (
    '__activeConfiguration' in videoSpecOrVideoDescriptor &&
    'projectManifest' in videoSpecOrVideoDescriptor &&
    'templateManifest' in videoSpecOrVideoDescriptor
  ) {
    videoSpecOrVideoDescriptor.__activeConfiguration = getSanitizedConfiguration(
      videoSpecOrVideoDescriptor.__activeConfiguration,
    );
    let templateSlug: string;
    if ('templateSlug' in videoSpecOrVideoDescriptor) {
      templateSlug = videoSpecOrVideoDescriptor.templateSlug as string;
    } else {
      templateSlug = videoSpecOrVideoDescriptor.__templateSlug;
    }
    return {
      __templateSlug: templateSlug,
      __activeConfiguration: videoSpecOrVideoDescriptor.__activeConfiguration,
      projectManifest: videoSpecOrVideoDescriptor.projectManifest as ProjectManifest,
      templateManifest: videoSpecOrVideoDescriptor.templateManifest as TemplateManifest,
    };
  }

  // At this point, we probably have either a video spec with an old-style configuration OR a thin autofill video descriptor,
  // so we'll need to fetch the template bundle to fill in the missing template manifest and project manifest
  let __templateSlug: string;
  let __activeConfiguration: VideoConfiguration;

  if ('templateSlug' in videoSpecOrVideoDescriptor) {
    __templateSlug = videoSpecOrVideoDescriptor.templateSlug as string;
  } else if ('__templateSlug' in videoSpecOrVideoDescriptor) {
    __templateSlug = videoSpecOrVideoDescriptor.__templateSlug;
  } else {
    __templateSlug = (
      'variant_slug' in videoSpecOrVideoDescriptor
        ? videoSpecOrVideoDescriptor.variant_slug
        : videoSpecOrVideoDescriptor.variantSlug
    ).split('.')[0];
  }

  if ('__activeConfiguration' in videoSpecOrVideoDescriptor) {
    __activeConfiguration = videoSpecOrVideoDescriptor.__activeConfiguration;
  } else {
    __activeConfiguration = videoSpecOrVideoDescriptor.configuration as VideoConfiguration;
  }

  const templateBundle = await WaymarkAuthorBundleManager.getBundleData(__templateSlug);
  const projectManifest = templateBundle.projectManifest;
  const templateManifest = templateBundle.templateManifest;

  __activeConfiguration = getSanitizedConfiguration(__activeConfiguration);

  return {
    __templateSlug,
    __activeConfiguration,
    projectManifest,
    templateManifest,
  };
};

import { BooleanInt } from './helpers';
import {
  MultiDimensionalValue,
  MultiDimensionalValueKeyframed,
  Value,
  ValueKeyframed,
} from './values';

// Effects
export enum EffectType {
  SliderControl = 0,
  AngleControl = 1,
  ColorControl = 2,
  PointControl = 3,
  CheckboxControl = 4,
  Group = 5,
  NoValue = 6,
  DropDownControl = 7,
  CustomValue = 9,
  LayerIndex = 10,
  /**
   * Technically this should be 11, but there's an error in bodymovin where it specifies
   * the wrong type.
   *
   * https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/utils/effectsHelper.jsx#L195
   */
  MaskIndex = 10,
  Tint = 20,
  Fill = 21,
  Stroke = 22,
  Tritone = 23,
  ProLevels = 24,
  DropShadow = 25,
  RadialWipe = 26,
  DisplacementMap = 27,
  Matte3 = 28,
  GaussianBlur2 = 29,
  Twirl = 30,
  MeshWarp = 31,
  Ripple = 32,
  Spherize = 33,
  FreePin3 = 34,
  Geometry2 = 35,
}

export interface BaseEffect {
  /** Effect Index. Used for expressions. */
  ix: number;
  /** After Effect's Match Name. Used for expressions. */
  mn: string;
  /** Number of effect properties. */
  np: number;
  /** After Effect's Name. Used for expressions. */
  nm: string;
  /** Effect type. (Bodymovin-defined enum) */
  ty: EffectType;
  /** Enabled AE property value */
  en: BooleanInt;
  /**
   * Effect List of properties.
   *
   * There's an odd case in the code where {} will be returned if the effect
   * is a "customControl".
   *
   * https://github.com/stikdev/bodymovin-extension-private/blob/5109465daf3d5fc902d66267b4159f9676f1029d/bundle/jsx/utils/effectsHelper.jsx#L203
   * */
  ef: Array<EffectControl>;
}

export interface UnknownEffect extends BaseEffect {
  /** Effect type. */
  ty: EffectType.Group;
  /** Number of effect properties. */
  np: number;
}

export interface TintEffect extends BaseEffect {
  ty: EffectType.Tint;
  /** Effect List of properties. */
  ef: (ColorEffectControl | SliderEffectControl | NoValueEffectControl)[];
}

export interface FillEffect extends BaseEffect {
  ty: EffectType.Fill;
  /** Effect List of properties. */
  ef: (
    | PointEffectControl
    | DropDownEffectControl
    | ColorEffectControl
    | SliderEffectControl
    | LayerIndexEffectControl
  )[];
}

export interface StrokeEffect extends BaseEffect {
  ty: EffectType.Stroke;
  /** Effect List of properties. */
  ef: (
    | LayerIndexEffectControl
    | ColorEffectControl
    | CheckBoxEffectControl
    | SliderEffectControl
    | DropDownEffectControl
  )[];
}
export interface TritoneEffect extends BaseEffect {
  ty: EffectType.Tritone;
  /** Effect List of properties. */
  ef: (ColorEffectControl | SliderEffectControl)[];
}

export interface ProLevelsEffect extends BaseEffect {
  ty: EffectType.ProLevels;
  /** Effect List of properties. */
  ef: (DropDownEffectControl | NoValueEffectControl | SliderEffectControl)[];
}

export interface DropShadowEffect extends BaseEffect {
  ty: EffectType.DropShadow;
}

export interface RadialWipeEffect extends BaseEffect {
  ty: EffectType.RadialWipe;
}
export interface DisplacementMapEffect extends BaseEffect {
  ty: EffectType.DisplacementMap;
}
export interface Matte3Effect extends BaseEffect {
  ty: EffectType.Matte3;
}
export interface GaussianBlur2Effect extends BaseEffect {
  ty: EffectType.GaussianBlur2;
}
export interface TwirlEffect extends BaseEffect {
  ty: EffectType.Twirl;
}
export interface MeshWarpEffect extends BaseEffect {
  ty: EffectType.MeshWarp;
}
export interface RippleEffect extends BaseEffect {
  ty: EffectType.Ripple;
}
export interface SpherizeEffect extends BaseEffect {
  ty: EffectType.Spherize;
}
export interface FreePin3Effect extends BaseEffect {
  ty: EffectType.FreePin3;
}

export type Effect =
  | TintEffect
  | FillEffect
  | StrokeEffect
  | TritoneEffect
  | ProLevelsEffect
  | DropShadowEffect
  | RadialWipeEffect
  | DisplacementMapEffect
  | Matte3Effect
  | GaussianBlur2Effect
  | TwirlEffect
  | MeshWarpEffect
  | RippleEffect
  | SpherizeEffect
  | FreePin3Effect
  | UnknownEffect;

export interface BaseEffectControl {
  /** Effect type. (Bodymovin-defined enum) */
  ty: EffectType;
  /** After Effect's Name. Used for expressions. */
  nm: string;
  /** After Effect's Match Name. Used for expressions. */
  mn: string;
  /** Effect Index. Used for expressions. */
  ix: number;
  /** The value of the control */
  v: unknown;
}
export interface ValueEffectControl extends BaseEffectControl {
  v: Value | ValueKeyframed;
}

export interface MultiDimensionalEffectControl extends BaseEffectControl {
  v: MultiDimensionalValue | MultiDimensionalValueKeyframed;
}

export interface SliderEffectControl extends ValueEffectControl {
  ty: EffectType.SliderControl;
}

export interface AngleEffectControl extends ValueEffectControl {
  ty: EffectType.AngleControl;
}
export interface ColorEffectControl extends MultiDimensionalEffectControl {
  ty: EffectType.ColorControl;
}

export interface PointEffectControl extends MultiDimensionalEffectControl {
  ty: EffectType.PointControl;
}
export interface CheckBoxEffectControl extends ValueEffectControl {
  ty: EffectType.CheckboxControl;
}

export interface NoValueEffectControl extends BaseEffectControl {
  ty: EffectType.NoValue;
  v: 0;
}

export interface DropDownEffectControl extends ValueEffectControl {
  ty: EffectType.DropDownControl;
}

export type CustomValueEffectControl = Record<string, never>;

export interface LayerIndexEffectControl extends BaseEffectControl {
  ty: EffectType.LayerIndex;
  /** Effect value. */
  v: Value;
}
export interface MaskIndexEffectControl extends BaseEffectControl {
  ty: EffectType.MaskIndex;
  /** Effect value. */
  v: Value;
}

export type EffectControl =
  | SliderEffectControl
  | AngleEffectControl
  | ColorEffectControl
  | PointEffectControl
  | CheckBoxEffectControl
  | NoValueEffectControl
  | DropDownEffectControl
  | CustomValueEffectControl
  | LayerIndexEffectControl
  | MaskIndexEffectControl;

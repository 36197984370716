// Local
import baseAPI from 'shared/api/core/base.js';
import { addQueryParametersToURL } from 'shared/utils/urls.js';

/**
 * @typedef {object} AutofillGenerateVideoResponse
 *
 * @property {string} awsRequestID - ID generated by the Autofill Service to mark individual AWS transactions
 * @property {string} message -
 * @property {object} requestContext
 * @property {string} requestContext.callbackURL - Where async responses to this request will be sent
 * @property {object} requestContext.requestContext - Data sent with original request, will also be sent with async response
 */
/**
 * Make a POST request to the `generate-video/` endpoint. A synchronous HTTP response is returned from this call,
 * but the Autofill Service, which is invoked in this call, responds asynchronously.
 *
 * @param {object} params
 * @param {string} params.requestGUID - Unique string used to identify async responses to this request
 * @param {string} params.requestSource - Where the request originated from
 * @param {string} params.businessGUID - GUID associated with Business model instance. Model instance data used to create personalized variant configuration.
 * @param {string} params.compoundSlug - Two-part slug referencing a VideoTemplate and VideoTemplateVariant. The variant configuration will be personalized with business data.
 * @param {string|null} [params.userVideoGUID] - GUID associated with UserVideo instance. Possible that this does not exist yet, only used for logging.
 * @param {string|null} [params.userInstructions] - Instructions from the user for what they want the generated video to be.
 * @param {string|null} [params.vibe] - The vibe of the video
 *
 * @returns {AutofillGenerateVideoResponse}
 */
export const generateVideo = async ({
  requestGUID,
  requestSource,
  businessGUID,
  compoundSlug,
  userVideoGUID,
  userInstructions,
  vibe,
}) => {
  const url = 'generate-video/';

  return baseAPI.post(url, {
    request_guid: requestGUID,
    request_source: requestSource,
    business_guid: businessGUID,
    compound_slug: compoundSlug,
    user_video_guid: userVideoGUID,
    user_instructions: userInstructions,
    vibe: vibe,
  });
};

/**
 * @typedef {object} AutofillEditVideoResponse
 *
 * @property {string} awsRequestID - ID generated by the Autofill Service to mark individual AWS transactions
 * @property {string} message -
 * @property {object} requestContext
 * @property {string} requestContext.callbackURL - Where async responses to this request will be sent
 * @property {object} requestContext.requestContext - Data sent with original request, will also be sent with async response
 */
/**
 * Make a POST request to the `generate-video/` endpoint. A synchronous HTTP response is returned from this call,
 * but the Autofill Service, which is invoked in this call, responds asynchronously.
 *
 * @param {object} params
 * @param {string} params.requestGUID - Unique string used to identify async responses to this request
 * @param {string} params.requestSource - Where the request originated from
 * @param {string} params.businessGUID - GUID associated with Business model instance. Model instance data used to create personalized variant configuration.
 * @param {string} params.newVariantAspectRatio - String representing the aspect ratio of the new variant (ie, "16:9", "4:5")
 * @param {number} params.newVariantDuration - Duration of the new variant in seconds
 * @param {string} params.originalUserVideoGUID - GUID associated with UserVideo instance.
 * @param {string} [params.userInstructions] - Instructions from the user for what they want the generated video to be.
 * @param {string|null} [params.vibe] - The vibe of the video
 *
 * @returns {AutofillGenerateVideoResponse}
 */
export const omniVideo = async ({
  requestGUID,
  requestSource,
  businessGUID,
  newVariantAspectRatio,
  newVariantDuration,
  originalUserVideoGUID,
  userInstructions,
  vibe,
}) => {
  const url = 'omni-video/';

  return baseAPI.post(url, {
    request_guid: requestGUID,
    request_source: requestSource,
    business_guid: businessGUID,
    new_variant_aspect_ratio: newVariantAspectRatio,
    new_variant_duration: newVariantDuration,
    original_user_video_guid: originalUserVideoGUID,
    user_instructions: userInstructions,
    vibe: vibe,
  });
};

/**
 * @typedef {object} AutofillGeneratedVideoResponse
 *
 * @property {string} requestGUID - The GUID of the request
 * @property {string} error - Any error generated by the process
 * @property {object} videoDescriptor - The video generated, if no error
 * @property {boolean} isComplete - True if the request has been completed
 */

/**
 * Make a GET request to the `get-generated-video/` endpoint. If the request has been completed
 * by the Autofill service then the response will be returned. This response has the same
 * format as the pushed asynchronous response.
 *
 * @param {string} requestGUID - Unique string used to identify async responses to this request
 *
 * @returns {AutofillGeneratedVideoResponse}
 */
export const getGeneratedVideo = async (requestGUID) => {
  const url = addQueryParametersToURL('get-generated-video/', {
    requestGUID,
  });

  return baseAPI.get(url);
};

export const upscaleImageLibraryImage = async (imageLibraryImageGUID) =>
  baseAPI.post('upscale-image/', {
    image_library_image_guid: imageLibraryImageGUID,
  });

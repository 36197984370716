export const checkoutPhases = {
  loading: 'loading',
  purchase: 'purchase',
  nps: 'nps',
};

export const regularFeatureList = [
  'Full template library',
  'Waymark video editor',
  'Share video previews',
  'Email and chat support',
  'Expert phone support',
  'HD downloads',
  'TV quality downloads',
];

export const subscriberOnlyFeatureList = ['Edit after purchase'];

export const subscriptionIntervalDescriptions = {
  year: 'annually',
  month: 'monthly',
};

export const subscriptionIntervalPriorities = {
  year: 10,
  month: 5,
};
